import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/donald-alfred-stephenson-jr.jpg";
  const fullName = "Donald Alfred Stephenson Jr.";
  const zoomUrl = "https://us02web.zoom.us/j/86275727043";
  const zoomPhoneNumber = "(646) 558 8656";
  const zoomMeetingId = "862 7572 7043";
  const birthDate = "July 12, 1978";
  const deathDate = "October 23, 2020";
  const memorialDate = "Saturday November 21st, 2020";
  const memorialTime = "11am CST";
  const inviteText =
    "Donald Alfred Stephenson Jr. left this earth to be embraced by his heavenly father October 23, 2020. Donald loved life and loved his family.  We are gathering to honor the life, share memories and say good bye to our loved one for the last time. When we can all be together - we will. And we will raise a toast to a honorable young man. For now, in lieu of a physical ceremony, the family of Donald Jr. (DJ) invites you to a virtual service celebrating his life.";
  const obituary = `Donald Alfred Stephenson Jr. was born July 12, 1978 to Donald Alfred Stephenson Sr. and Annette M. Stephenson in Chicago Heights, IL.  He began his career as an Accountant after graduating from Robert Morris University.
    \nDon lived his life to the fullest, traveling, enjoying friend and family alike.  He was full of life, passionately outspoken about his beliefs and sparked the party when he entered the room.  He had an uncanny way of making you feel as if you’d seen him yesterday even after being away for months and even years. He never failed to lend a listening ear and tell you what you needed to hear not want you wanted to hear.  His love for his family ran deep and his favorite saying passed down from his dad was, ”I would kill a brick, chock a stick and drown a glass of water for my family”.
    \nDonald (DJ) left this life to prepare a place in heaven for his loved ones he left behind on October 23, 2020.  He leaves behind his adoring parents, Donald Stephenson Sr. and Annette Stephenson-Selmon (Clyde); sister, Tanisha Stephenson-Mc Combs (Reginald); niece, Destiny Stephenson and great niece Skylar McGrown; paternal grandmother, Francis Johnson; maternal grandmother, Verneal Childress; step-sisters, Ava Green (Frank) & Leah LeGrant (Todd).  Donald (DJ) also leaves behind countless loving aunts, uncles, cousins and extended family members.  We will miss you terribly Don, Don-Don, DJ, Bookie, until we embrace again.`;
  const storiesRecordUrl = "https://stories.afterword.com/fw59hs2cy";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/r547ej06o486s13fp1hkp3fc4wbhvhjmtom85tgx";
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      optInviteChild=""
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      dropboxUrl={dropboxUrl}
    ></StoryPage>
  );
};

export default ThisPage;
